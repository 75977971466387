import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';

import {Layout as DashboardLayout} from 'src/layouts/dashboard';
import {DashboardFooter} from "../layouts/dashboard/dashboard-footer";
import {RoleBasedGuard} from "../guards/role-based-guard.jsx";
import {roles} from "../utils/user-roles.js";

const DataManagerDemoRequestPage = lazy(() => import('src/pages/dashboard/data-management/demo-request'));
const DataManagerEmailDemoRequestPage = lazy(() => import('src/pages/dashboard/data-management/email-demo-request'));
const ProductListPage = lazy(() => import('src/pages/admin/product-list'));
const CreateProductPage = lazy(() => import('src/pages/admin/create-product'));
const UpdateProductPage = lazy(() => import('src/pages/admin/update-product'));
const OfferListPage = lazy(() => import('src/pages/admin/offer-list'));
const ClarityListPage = lazy(() => import('src/pages/admin/clarity-list'));

export const adminRoutes = [
    {
        path: 'admin',
        element: (
            <>
                <RoleBasedGuard roles={[roles.ROLE_SUPER_ADMIN]}>
                    <DashboardLayout>
                        <Suspense>
                            <Outlet />
                        </Suspense>
                    </DashboardLayout>
                    <DashboardFooter />
                </RoleBasedGuard>
            </>
        ),
        children: [
            {
                path: 'demo-requests',
                element: <DataManagerDemoRequestPage />
            },
            {
                path: 'email-demo-requests',
                element: <DataManagerEmailDemoRequestPage />
            },
            {
                path: 'product-list',
                element: <ProductListPage />
            },
            {
                path: 'product/create',
                element: <CreateProductPage/>
            },
            {
                path: ':productId/update-product',
                element: <UpdateProductPage/>
            },
            {
                path: 'offer-list',
                element: <OfferListPage />
            },
            {
                path: 'clarity-list',
                element: <ClarityListPage />
            },
        ],
    },
];
