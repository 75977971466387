import { tokens } from '../tokens';

export const en = {
  [tokens.common.languageChanged]: 'Language changed',
  [tokens.nav.academy]: 'Academy',
  [tokens.nav.account]: 'Account',
  [tokens.nav.companyAccount]: 'Company Account',
  [tokens.nav.analytics]: 'Analytics',
  [tokens.nav.auth]: 'Auth',
  [tokens.nav.blog]: 'Blog',
  [tokens.nav.browse]: 'Browse',
  [tokens.nav.calendar]: 'Calendar',
  [tokens.nav.chat]: 'Chat',
  [tokens.nav.checkout]: 'Checkout',
  [tokens.nav.concepts]: 'Concepts',
  [tokens.nav.contact]: 'Contact',
  [tokens.nav.course]: 'Course',
  [tokens.nav.create]: 'Create',
  [tokens.nav.crypto]: 'Crypto',
  [tokens.nav.customers]: 'Customers',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.details]: 'Details',
  [tokens.nav.ecommerce]: 'E-Commerce',
  [tokens.nav.edit]: 'Edit',
  [tokens.nav.error]: 'Error',
  [tokens.nav.feed]: 'Feed',
  [tokens.nav.fileManager]: 'File Manager',
  [tokens.nav.finance]: 'Finance',
  [tokens.nav.fleet]: 'Fleet',
  [tokens.nav.forgotPassword]: 'Forgot Password',
  [tokens.nav.invoiceList]: 'Invoices',
  [tokens.nav.jobList]: 'Job Listings',
  [tokens.nav.kanban]: 'Kanban',
  [tokens.nav.list]: 'List',
  [tokens.nav.login]: 'Login',
  [tokens.nav.logistics]: 'Logistics',
  [tokens.nav.mail]: 'Mail',
  [tokens.nav.management]: 'Management',
  [tokens.nav.orderList]: 'Orders',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.pages]: 'Pages',
  [tokens.nav.postCreate]: 'Post Create',
  [tokens.nav.postDetails]: 'Post Details',
  [tokens.nav.postList]: 'Post List',
  [tokens.nav.pricing]: 'Pricing',
  [tokens.nav.productList]: 'Products',
  [tokens.nav.profile]: 'Profile',
  [tokens.nav.register]: 'Register',
  [tokens.nav.resetPassword]: 'Reset Password',
  [tokens.nav.socialMedia]: 'Social Media',
  [tokens.nav.verifyCode]: 'Verify Code',
  [tokens.nav.dataManagerImportBatch] : 'Upload File',
  [tokens.nav.dataManager]: 'Data Management',
  [tokens.nav.dataManagerItn]: 'Print Labels',
  [tokens.nav.dataManagerTakePhoto]: 'Take Photo',
  [tokens.nav.dataManagerListing]: 'Listing Management',
  [tokens.nav.dataManagerCreate]: 'Create Item',
  [tokens.nav.dataManagerDescribeInventory]: 'Describe and List',
  [tokens.nav.dataManagerProduct]: 'Product Management',
  [tokens.nav.dataManagerDashboardInvInfo]: 'Inventory Dashboard',
  [tokens.nav.scanInventoryItem]: 'Shipment Management',
  [tokens.nav.addItem]: 'Create a New Item',
  [tokens.nav.mainFlow]: 'Main Flow',
  [tokens.nav.information]: 'Information',
  [tokens.nav.privacyPolicy]: 'Privacy Policy',
  [tokens.nav.termsService]: 'Terms of Service',
  [tokens.nav.aboutUs]: 'About Us',
  [tokens.nav.faq]: 'FAQ',
  [tokens.nav.admin]: 'Administrator',
  [tokens.nav.marketplace]: 'Marketplace',
  [tokens.nav.marketplaceImport]: 'Import',
  [tokens.nav.marketplaceBatchItems]: 'Batch Items',
  [tokens.nav.marketplaceDescribeInventory]: 'Marketplace Describe and List',
  [tokens.nav.demoRequest]: 'Demo Requests',
  [tokens.nav.emailDemoRequest]: 'Email Demo Requests',
  [tokens.nav.marketplaceProduct]: 'Marketplace Product Management',
  [tokens.nav.adminProducts]: 'Product List',
  [tokens.nav.adminOffers]: 'Offer List',
  [tokens.nav.clarityList]: 'Clarity List',
};
